<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @opened='onOpened' @close='onClose' :title='dialogTitle' width='800px'>
      <div>
        <el-form inline>
          <el-form-item>
            <el-date-picker
              v-model='searchCondition.month'
              type='month'
              format='yyyy年MM月'
              value-format='yyyy-MM-01'
              placeholder='选择月' @change='getCheckList'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-form-item>
              <PlatformSelect ref='refPlatformSelect' :range-all='true' v-model='searchCondition.platform_code'
                              @change='getCheckList' />
            </el-form-item>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='getCheckList'>查看进度</el-button>
          </el-form-item>
          <el-form-item>

            <export-btn type='warning' ref='refExportLink' :title='`下载进度当前文件`' @export='exportData'
                        :lint-title='`导出进度`' :can-export='true'></export-btn>
          </el-form-item>
          <el-form-item>
            进度：
            <span style='font-weight: bold;color: red;font-size: 1.2em;margin-right: 5px'>{{ info.done_count || 0
              }}</span>
            /
            <span style='font-weight: bold;font-size: 1.2em;margin-right: 5px'>{{ info.total || 0 }}</span>
          </el-form-item>
        </el-form>
      </div>
      <div class='default-table'>
        <el-table :data='dataList' max-height='600' border>
          <el-table-column type='index' label='序号' width='60' align='center'></el-table-column>
          <el-table-column prop='nickname' label='红人昵称' min-width='200' align='center' sortable></el-table-column>
          <el-table-column prop='dept_name' label='组名' min-width='120' align='center' sortable></el-table-column>
          <el-table-column prop='leader_name' label='组长' min-width='120' align='center' sortable></el-table-column>
          <el-table-column prop='is_record' label='录入状态' min-width='120' align='center' sortable>
            <template slot-scope='{row}'>
              <el-tag type='success' v-if="row.is_record==='Y'">是</el-tag>
              <el-tag type='danger' v-else>否</el-tag>
            </template>
          </el-table-column>
          <!--          <el-table-column label='操作' width='160' align='center'>-->
          <!--            <template>-->
          <!--              <el-button type='text' icon='el-icon-s-promotion'>通知录入</el-button>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
        <div>
          <tips :data='tipList'></tips>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PlatformSelect from '@/pages/platform/components/PlatformSelect'
import ExportBtn from '@/components/export/ExportBtn'

export default {
  name: 'BalanceProgress',
  components: { ExportBtn, PlatformSelect },
  computed: {
    dialogTitle() {
      return `红人平台账号余额-进度汇总`
    }
  },
  data() {
    return {
      searchCondition: { month: null, platform_code: null },
      dataList: [
        // { nickname: '郑伊summer', 'leader_name': '王飞', is_record: 'N' },
        // { nickname: '杨一闪', 'leader_name': '王飞1', is_record: 'N' },
        // { nickname: '热心主播杨美丽', 'leader_name': '王飞2', is_record: 'Y' }
      ],
      info: {},
      tipList: [
        '查看进度需要选择月份和平台',
        '若查看以往月份的进度，可能会因为红人的平台账号开通情况的变更导致进度汇总不准确'
      ]
    }
  },
  methods: {
    onOpened() {
      this.searchCondition.month = this.$utils.getCurrentMonth()
      // this.getCheckList()
    },
    onClose() {

    },
    async getCheckList() {
      this.dataList = []
      if (this.searchCondition.month && this.searchCondition.platform_code) {
        let { list, info } = await this.$api.getBalanceCheckList(this.searchCondition)
        this.$nextTick(() => {
          this.dataList = list
          this.info = info
        })
      }
    },
    async exportData() {
      try {
        if (this.searchCondition.month && this.searchCondition.platform_code) {
          let platform_name = this.$refs.refPlatformSelect.getPlatformName(this.searchCondition.platform_code)
          let name = `${this.$utils.parseTime(this.searchCondition.month, '{y}年{m}月')}【${platform_name}】平台数据录入进度`
          let response = await this.$api.exportBalanceCheckList(this.searchCondition)
          this.$refs[`refExportLink`].exportSaveXlsx(response, name)
        } else {
          this.$notify.warning('月份和平台必选')
          this.$refs.refExportLink.hideLoading()
        }
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refExportLink.hideLoading()
      }
    }
  }
}
</script>

<style scoped>

</style>