<template>
<div>
  <div class="head-container">
    <el-input
      v-model="deptName"
      placeholder="请输入部门名称"
      clearable
      size="small"
      prefix-icon="el-icon-search"
      style="margin-bottom: 20px"
    />
    <el-button type='primary' size='small' @click='handleNodeClick([])'>全公司</el-button>
  </div>
  <el-tree :data="options" :props="defaultProps"
           :expand-on-click-node="false"
           :filter-node-method="filterNode"
           ref="deptTree"
           :default-expand-all='false'
           @node-click="handleNodeClick"></el-tree>
</div>
</template>

<script>
export default {
  name: 'DeptPanel',
  data(){
    return {
      options:[],
      multiple:true,
      defaultProps: {
        id:"dpt_id",
        children: "children",
        label: "name"
      },
      queryParams:{},
      deptName:''
    }
  },
  watch: {
    // 根据名称筛选部门树
    deptName(val) {
      this.$refs.deptTree.filter(val);
    }
  },
  methods:{
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data[this.defaultProps.label].indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.$emit("handleSelect",data)
      // this.queryParams.dpt_id = data.dpt_id;
      // this.getList();
    },
    async getList() {
      let { list } = await this.$api.getDepartmentAll()
      this.data = list
      // if(this.showFirstGroup){
      //   this.value = list[2].dpt_id
      // }

      this.calcOptions()
    },
    calcOptions() {
      this.options = this.handleTree(this.data, 'dpt_id', 'parent_id', 'children', '1')
      if (this.options.length > 0) {
        let dept = this.options[0]
        //计算第一个项目组
        this.calcLastDept(dept)
      }
    },
    calcLastDept(dept) {
      if (dept && dept['children']) {
        let firstChildDept = dept['children'][0]
        if (firstChildDept) {
          this.calcLastDept(firstChildDept)
        }
      } else {
        // console.log(dept)
        if (!this.multiple) {
          //单选
          this.value = dept.dpt_id || null
          // let deptSelect = this.data.find(value => value.dpt_id === this.value)
          // console.log(deptSelect)
          this.$emit('handleSelect', [this.value])
        }
      }
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>

</style>