<template>
  <div>
    <div class='head-container'>
      <el-input
        v-model='deptName'
        placeholder='请输入部门名称'
        clearable
        size='small'
        prefix-icon='el-icon-search'
        style='margin-bottom: 20px'
      >
        <template slot='append'>
          <el-button type='primary' icon='el-icon-refresh' @click='getList' :loading='loading'>刷新</el-button>
        </template>
      </el-input>

    </div>
    <div>
<!--      <div>-->
<!--        <el-button type='primary' icon='el-icon-position' @click='handleNodeClick([])' v-if='rangeType==="all"'>全公司</el-button>-->
<!--      </div>-->

      <el-tree :data='options' :props='defaultProps'
               :expand-on-click-node='false'
               :filter-node-method='filterNode'
               ref='deptTree'
               :default-expand-all='false'
               @node-click='handleNodeClick'></el-tree>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeptMcnPanel',
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo'])
  },
  props: {
    rangeType: {
      type: String,
      default() {
        return 'all'
      }
    }
  },
  data() {
    return {
      loading: false,
      options: [],
      multiple: true,
      defaultProps: {
        id: 'dpt_id',
        children: 'children',
        label: 'name'
      },
      queryParams: {},
      deptName: ''
    }
  },
  watch: {
    // 根据名称筛选部门树
    deptName(val) {
      this.$refs.deptTree.filter(val)
    },
    rangeType: {
      handler() {
        this.getList()
      }
    }
  },
  methods: {
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true
      return data[this.defaultProps.label].indexOf(value) !== -1
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.$emit('handleSelect', data)
      // this.queryParams.dpt_id = data.dpt_id;
      // this.getList();
    },
    async getList() {
      this.loading = true
      // let { list } = await this.$api.getMcnGroupDepts()
      let { list } = await this.$api.getDepartmentAll()
      // let { list } = await this.$api.getMcnGroupDeptOption()
      this.loading = false
      this.data = list
      this.calcOptions()
    },
    calcOptions() {
      if (this.rangeType === 'dpt') {
        let dpt_range = this.userDptInfo.dpt_range
        if (dpt_range) {
          this.data = this.data.filter((item) => {
            return dpt_range.indexOf(item.dpt_id) > -1
          })
        } else {
          this.data = []
        }
      } else if (this.rangeType === 'group') {
        let my_dpt_range = this.userDptInfo.my_dpt_range
        if (my_dpt_range) {
          this.data = this.data.filter((item) => {
            return my_dpt_range.indexOf(item.dpt_id) > -1
          })
        } else {
          this.data = []
        }
      }

      this.options = this.handleTree(this.data, 'dpt_id', 'parent_id', 'children', '1')
      if (this.options.length > 0) {
        let dept = this.options[0]
        //计算第一个项目组
        this.calcLastDept(dept)
      }
    },
    calcLastDept(dept) {
      if (dept && dept['children']) {
        let firstChildDept = dept['children'][0]
        if (firstChildDept) {
          this.calcLastDept(firstChildDept)
        }
      } else {
        // console.log(dept)
        if (!this.multiple) {
          //单选
          this.value = dept.dpt_id || null
          // let deptSelect = this.data.find(value => value.dpt_id === this.value)
          // console.log(deptSelect)
          this.$emit('handleSelect', [this.value])
        }
      }
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>

</style>