<template>
    <div @click="onFieldClick" class="edit-cell">
        <div v-if="!editMode && !showInput">
          <el-tooltip :content="toolTipContent"
                      :open-delay="toolTipDelay"
                      :placement="toolTipPlacement"
                      v-if="clickEdit"
          >
            <div @keyup.enter="onFieldClick" tabindex="0">
              <slot name="content"></slot>
            </div>
          </el-tooltip>
          <div @keyup.enter="onFieldClick" tabindex="0" v-else>
            <slot name="content"></slot>
          </div>
        </div>
      <template v-if="isInputNumber">
        <component
            :disabled="isDisabled"
            :is="editableComponent"
            @focus="onFieldClick"
            @blur="onInputExit($event)"
            @keyup.enter.native="onInputExit($event)"
            oninput="value=value.replace(/[^\d^\.^\-]/g,'')"
            class="edit-cell-input"
            clearable
            ref="input"
            size="mini"
            v-bind="$attrs" v-if="editMode || showInput" v-model="model" v-on="listeners">
          <slot name="edit-component-slot"></slot>
        </component>
      </template>
      <template v-else>
        <component :disabled="isDisabled"
                   :is="editableComponent"
                   @focus="onFieldClick"
                   @keyup.enter.native="onInputExit"
                   class="edit-cell-input"
                   clearable
                   ref="input"
                   size="mini"
                   v-bind="$attrs" v-if="editMode || showInput" v-model="model" v-on="listeners">
          <slot name="edit-component-slot"></slot>
        </component>
      </template>
    </div>
</template>
<script>
  export default {
    name: 'EditableCell',
    inheritAttrs: false,
    props: {
      value: {
        type: [String, Number],
        default: ''
      },
      toolTipContent: {
        type: String,
        default: '点击编辑'
      },
      toolTipDelay: {
        type: Number,
        default: 500
      },
      toolTipPlacement: {
        type: String,
        default: 'top-start'
      },
      showInput: {
        type: Boolean,
        default: false
      },
      editableComponent: {
        type: String,
        default: 'el-input'
      },
      closeEvent: {
        type: String,
        default: 'blur'
      },
      isInputNumber: {
        type: Boolean,
        default: false
      },
      isSelected: {
        type: Boolean,
        default: true
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      clickEdit: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        editMode: false
      }
    },
    computed: {
      model: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      },
      listeners() {
        return {
          [this.closeEvent]: this.onInputExit,
          ...this.$listeners
        }
      }
    },
    methods: {
      onFieldClick() {
        if (this.clickEdit) {
          this.editMode = true
          this.$nextTick(() => {
            let inputRef = this.$refs.input
            if (inputRef) {
              inputRef.focus()
              if (this.isSelected && this.editableComponent.indexOf('input') > -1)
                inputRef.select()//选中状态
            }
          })
        }

      },
      onInputExit($event) {
        if (this.isInputNumber) {
          this.model = $event.target.value
          this.$forceUpdate()
        }
        this.$forceUpdate()

        this.editMode = false
      },
      onInputChange(val) {
        this.$emit('input', val)
      }

    },
    mounted() {

    }
  }
</script>
