<template>
  <div style='max-height: 700px;min-width: 600px'>
    <el-row :gutter='15'>
      <el-col :span='12'>
        <div>
          <!--          <div>-->
          <!--            <label style='font-weight: bold'>部门：</label>-->
          <!--            <el-radio-group v-model='rangeType'>-->
          <!--              <el-radio-button :label='value' v-for='(label,value) in deptRange' :key='value'>{{ label }}-{{value}}-->
          <!--              </el-radio-button>-->
          <!--            </el-radio-group>-->
          <!--          </div>-->
          <div class='opt-box'>
            <CompanySelect v-if='rangeType==="group"' ref='refDeptCascader' :showFirstGroup='true'
                           style='width: 200px;display: inline;margin-right: 10px' type='trend_enter'
                           @handleSelect='selectDept' />
            <DeptMcnPanel v-else @handleSelect='handleSelectDept' />
          </div>
        </div>
      </el-col>
      <el-col :span='12'>
        <div>
          <!--          <div>-->
          <!--            <el-tag effect='plain' size='medium' type='primary'> 部门/组：{{ form.dept_path }}</el-tag>-->
          <!--          </div>-->
          <div class='opt-box'>
            <div style='margin: 15px  5px auto;'>
              <span style='font-size:14px;font-weight: bold'>在约红人：</span>
            </div>
            <el-radio-group v-model='artist_id' @change='handleSelectArtist'>
              <el-radio v-for='(item,index) in artistList' :key='index' :label='item.id' border
                        style='margin: 10px 5px;'>{{ item.nickname }}
              </el-radio>
            </el-radio-group>
          </div>

        </div>

      </el-col>
    </el-row>
    <div>
      <div style='text-align: center;margin: 10px;bottom: 10px'>
        <el-button size='medium' type='primary' @click='subCommit'>确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import DeptMcnPanel from '@/components/dept/DeptMcnPanel'
import { mapGetters } from 'vuex'

export default {
  name: 'ArtistSelectPanel',
  components: { DeptMcnPanel },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo'])
  },
  props: {
    rangeType: {
      type: String,
      default() {
        return 'group'
      }
    }
  },
  data() {
    return {
      deptMode: 'all',
      deptRange: { all: '全部', dpt: '本部', group: '本组' },
      artistList: [],
      artist_id: null,
      form: {}
    }
  },
  methods: {
    /**
     *
     * @param group_id
     */
    selectDept(valArr) {
      if (valArr && valArr.length != 0) {
        this.form.dept_id = valArr[0]
        this.form.dept_path = this.$refs['refDeptCascader'].getNodeName(this.form.dept_id)
        this.showArtistList(this.form.dept_id)
      } else {
        this.form.dept_id = null
        this.form.dept_path = null
      }

    },
    handleSelectDept(val) {
      this.form.dept_id = val.dpt_id
      this.form.dept_path = val.name
      this.showArtistList(this.form.dept_id)
    },
    handleSelectArtist(val) {
      this.form.artist_id = val
      const info = this.artistList.find(value => value.id === val)
      this.form.nickname = info ? info.nickname : ''
    },
    async showArtistList(dept_id) {
      let { list } = await this.$api.getGroupArtists(dept_id)
      this.$nextTick(() => {
        this.artistList = list
      })
    },
    subCommit() {
      this.$emit('saved', this.form)
    }
  }
}
</script>

<style scoped>
.opt-box {
  border: 1px #E4E7ED solid;
  margin: 10px auto;
  min-height: 300px;
  height: 300px;
  max-height: 500px;
  overflow-y: scroll;
  padding: 10px 5px;
}
</style>
