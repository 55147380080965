<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <el-form inline :model='searchCondition'>
          <el-form-item label=''>
            <el-date-picker
              v-model='searchCondition.date_range'
              type='monthrange'
              range-separator='至'
              format='yyyy年MM月'
              value-format='yyyy-MM-01'
              start-placeholder='开始月份'
              end-placeholder='结束月份'>
            </el-date-picker>
            <!--            <el-date-picker-->
            <!--              v-model='searchCondition.date_range'-->
            <!--              type='daterange'-->
            <!--              align='right'-->
            <!--              unlink-panels-->
            <!--              range-separator='至'-->
            <!--              start-placeholder='开始日期'-->
            <!--              end-placeholder='结束日期'-->
            <!--              value-format='yyyy-MM-dd'-->
            <!--              :picker-options='pickerOptions'>-->
            <!--            </el-date-picker>-->
          </el-form-item>
          <el-form-item>
            <PlatformSelect :range-all='true' v-model='searchCondition.platform_code' />
          </el-form-item>
          <el-form-item>
            <artist-search @handleSelect='changeArtist' />
          </el-form-item>

          <el-form-item>
            <el-button type='primary' :loading='loadingStatus' :disabled='loadingStatus' icon='el-icon-search'
                       @click='handleQuery'>搜索
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='showProgress' icon='el-icon-s-data'>查看进度</el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-plus' type='primary' @click='handleAdd'
                       v-if='userPermissions.indexOf("platform_balance_create")>-1'>添加记录
            </el-button>
          </el-form-item>
          <el-form-item>
            <export-btn type='warning' :ref='`refExportLink`' :title='`下载当前文件`' @export='exportData'
                        :lint-title='`导出`'
                        :can-export='userPermissions.indexOf("platform_balance_export")>-1'></export-btn>
          </el-form-item>
          <el-form-item style='float: right'>
            <el-tag effect='plain' type='success'>数据范围：
              <span v-if='userPermissions.indexOf("platform_balance_range_all")>-1'>全部</span>
              <span v-else-if='userPermissions.indexOf("platform_balance_range_dept")>-1'>本部</span>
              <span v-else-if='userPermissions.indexOf("platform_balance_range_group")>-1'>本组</span>
              <span v-else>无权限</span>
            </el-tag>
          </el-form-item>

        </el-form>
        <div class='default-table'>
          <m-table :showIndex='true' :offset='offset' max-height='1000' :tableData='dataList' :columns='columns'
                   :loading='loadingStatus' @sort-change='changeTableSort' border>
            <el-table-column label='操作' width='140' align='center'>
              <template slot-scope='{row}'>
                <el-button type='text' icon='el-icon-edit' @click='handleEdit(row)'
                           v-if='userPermissions.indexOf("platform_balance_edit")>-1'>修改
                </el-button>
                <el-button type='text' icon='el-icon-delete' @click='handleDel(row)'
                           v-if='userPermissions.indexOf("platform_balance_delete")>-1'>删除
                </el-button>
                <el-button type='text' icon='el-icon-document-copy' @click='handleCopy(row)'
                           v-if='userPermissions.indexOf("platform_balance_copy")>-1'>复制
                </el-button>
              </template>
            </el-table-column>
          </m-table>
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                        @pagination='getList' />
        </div>
      </div>
    </page-header-layout>
    <!-- 添加或修改软件项目对话框 -->
    <el-dialog :title='title' center :visible.sync='open' width='800px' append-to-body :close-on-click-modal='false'>
      <el-form ref='form' :model='form' :rules='rules' size='medium' label-width='80px'>
        <el-row :gutter='50'>
          <el-col :span='16'>
            <el-form-item label='部门/组'>
              <el-input v-model='form.dept_path' @focus='showDeptSelect'>
              </el-input>
            </el-form-item>
            <el-form-item label='红人昵称'>
              <el-input v-model='form.nickname' @focus='showDeptSelect'>
              </el-input>
            </el-form-item>
            <el-form-item label='平台'>
              <!--              <el-input v-if='form.id' v-model='form.platform_name'></el-input>-->
              <PlatformSelect @saved='handleSelectPlatform' :range-all='true' v-model='form.platform_code'
                              :default-code='form.platform_code' />
            </el-form-item>
            <el-form-item label='月份' prop='month'>
              <el-date-picker
                v-model='form.month'
                type='month'
                value-format='yyyy-MM-01'
                format='yyyy年MM月'
                placeholder='选择月'>
              </el-date-picker>
            </el-form-item>
            <el-form-item label='账号余额' prop='balance'>
              <el-input oninput="value=value.replace(/[^\d^\.^\-]/g,'')" placeholder='必须是数值,最多保留2位小数位（单位：元）'
                        v-model='form.balance' clearable>
                <template slot='append'>
                  <span>元</span>
                </template>
              </el-input>
              <span v-if='form.balance' class='digit-Uppercase'>{{ this.$utils.digitUppercase(form.balance) }}</span>
            </el-form-item>
          </el-col>
          <el-col :span='8'>
            <div style='margin-bottom: 10px'><span style='color: red'>*</span>余额截图</div>
            <ape-uploader :upload-file-list='uploadFileList' @handleUploadRemove='handleUploadRemove'
                          @handleUploadSuccess='handleUploadSuccess' />
          </el-col>
        </el-row>
      </el-form>
      <!--      部门选择器-->
      <el-dialog
        width='800px'
        title='部门-红人选择'
        :visible.sync='showDeptDialog'
        append-to-body>
        <!--        部门红人选择器-->
        <ArtistDeptPanel @saved='handleSelected' />
      </el-dialog>
      <div slot='footer' class='dialog-footer'>
        <el-button type='primary' size='medium' @click='submitForm' :loading='loadingCommit' :disabled='loadingCommit'>确
          定
        </el-button>
        <el-button @click='cancel' size='medium'>取 消</el-button>
      </div>
    </el-dialog>

    <!--    余额登记记录-->
    <BalanceProgress :visible.sync='showProgressDialog'></BalanceProgress>

  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import PlatformSelect from '@/pages/platform/components/PlatformSelect'
import ArtistSearch from '@/components/artist/ArtistSearch'
import ApeUploader from '@/components/ApeUploader'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import EditableCell from '@/components/EditableCell'
import DeptPanel from '@/components/dept/DeptPanel'
import ArtistDeptPanel from '@/components/artist/ArtistDeptPanel'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import ExportLink from '@/components/export/ExportLink'
import BalanceProgress from '@/pages/balance/BalanceProgress'

export default {
  name: 'BalanceIndex',
  components: {
    BalanceProgress,
    ExportLink,
    ExportBtn,
    ArtistDeptPanel,
    DeptPanel,
    EditableCell,
    ArtistSearchCustom,
    ApeUploader,
    ArtistSearch,
    PlatformSelect,
    PageHeaderLayout
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      // 是否显示弹出层
      open: false,
      innerVisible: false,
      loadingStatus: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 10,
        current_page: 1
      },
      orderSort: { month: 'desc', id: 'desc' },
      searchCondition: {},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        {
          title: '部门-组',
          value: 'dept_path',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '月份',
          value: 'month_alias',
          field: 'month',
          width: 100,
          sortable: 'custom'
        },
        {
          title: '红人昵称',
          value: 'nickname',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '平台',
          value: 'platform_name',
          width: 100,
          sortable: 'custom'
        },
        {
          title: '截图',
          value: 'image_link',
          type: 'image',
          width: 50
        },
        {
          title: '账号余额',
          value: 'balance',
          width: 100,
          sortable: 'custom',
          header_align: 'center',
          align: 'right'
        },
        {
          title: '创建人',
          value: 'creator',
          width: 100,
          sortable: 'custom'
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120,
          sortable: 'custom'
        }
      ],
      // 弹出层标题
      title: '',
      // 表单参数
      form: {
        'dept_path': null,
        'dept_id': null,
        'nickname': null,
        'artist_id': null,
        'platform_code': null,
        'platform_name': null,
        'month': null,
        'balance': null,
        'image_id': null,
        'image_link': null
      },
      uploadFileList: [],
      // 表单校验
      rules: {
        month: [{ required: true, message: '月份必选', trigger: 'blur' }],
        platform_code: [{ required: true, message: '平台必选', trigger: 'blur' }],
        balance: [{ required: true, message: '余额必填', trigger: 'blur' }]
      },
      //部门选择器
      showDeptDialog: false,
      //查看进度
      showProgressDialog: false


    }
  },
  methods: {
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }
      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      if (this.userPermissions.indexOf('platform_balance_range_all') > -1) {
        condition.data_range = 'all'
      } else if (this.userPermissions.indexOf('platform_balance_range_dept') > -1) {
        condition.data_range = 'dept'
      } else if (this.userPermissions.indexOf('platform_balance_range_group') > -1) {
        condition.data_range = 'group'
      }

      return condition
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()

    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getBalanceList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })
    },
    // 表单重置
    reset() {
      this.form = {
        'dept_path': null,
        'dept_id': null,
        'nickname': null,
        'artist_id': null,
        'platform_code': null,
        'platform_name': null,
        'month': null,
        'balance': null,
        'image_id': null,
        'image_link': null
      }
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.loadingCommit = false
      // this.reset();
    },
    changeArtist(val) {
      this.searchCondition.artist_id = val ? val.id : ''
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.uploadFileList = []
      this.open = true
      this.title = '添加月度余额'
    },
    async handleEdit(row) {
      this.open = true
      this.title = '修改月度余额'
      let { info } = await this.$api.getBalanceInfo(row.id)
      this.uploadFileList = [{ id: info.image_id, url: info.image_link }]
      this.form = info
    },
    async handleDel(row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = await this.$api.delBalanceInfo(row.id)
        if (data)
          await this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async handleCopy(info) {
      delete info.id
      delete info.created_at
      delete info.updated_at
      this.form = { ...info }
      this.form.image_id = null
      this.form.image_link = null
      this.form.balance = null
      this.form.month = null
      // delete info.platform_code
      // delete info.platform_name
      this.uploadFileList = []
      this.title = '添加月度余额'
      // this.uploadFileList = [{ id: info.image_id, url: info.image_link }]

      this.open = true
    },
    handleUploadSuccess(file) {
      this.form.image_id = file.id
      this.form.image_link = file.full_path
    },
    handleUploadRemove() {
      this.form.image_id = null
      this.form.image_link = null
      // this.uploadFileList=[]
    },
    async saveData(form) {
      if (!form.image_link) {
        this.msgWarn('余额截图必传')
        return false
      }
      this.loadingCommit = true
      let id = await this.$api.storeBalanceInfo(form)
      if (id) {
        if (form.id != undefined) {
          this.msgSuccess('修改成功')
        } else {
          this.form.id = id
          this.msgSuccess('新增成功')
        }
        this.loadingCommit = false
        setTimeout(() => {
          this.open = false
        }, 500)
        this.handleQuery()
      }

    },
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.saveData(this.form)
          setTimeout(() => {
            this.loadingCommit = false
          }, 1000)
        }
      })
    },
    showDeptSelect() {
      this.showDeptDialog = true
    },
    //确认选择部门、红人
    handleSelected(val) {
      this.form.dept_path = val.dept_path
      this.form.dept_id = val.dept_id
      this.form.nickname = val.nickname
      this.form.artist_id = val.artist_id
      this.showDeptDialog = false
    },
    handleSelectPlatform(val) {
      this.form.platform_code = val ? val.code : ''
      this.form.platform_name = val ? val.name : ''
    },
    async exportData() {
      try {
        let name = `账号余额`
        let searchCondition = this.handleSearchCondition()
        // 合并
        Object.assign(searchCondition, this.pagingData, { order: this.orderSort })

        let response = await this.$api.exportBalanceExcel(searchCondition)

        this.$refs[`refExportLink`].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    showProgress() {
      this.$notify.warning('查看进度')
      this.showProgressDialog = true
    }

  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>
.digit-Uppercase {
  border: #000c17 1px dashed;
  line-height: 20px;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
