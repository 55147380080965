<template>
  <div style="display: inline-block;">
    <el-link :underline="true" type="primary" :class="className" @click="exportSaveFile" v-if="canExport"
             :disabled="loadingDownload">
      <i :class="`${loadingDownload?'el-icon-loading':'el-icon-download'}`"></i>
      {{ loadingDownload ? '导出中...' : lintTitle }}
    </el-link>
    <el-tooltip class="item" effect="dark" content="无权限：导出" placement="top-start" v-else>
      <el-link :underline="false" type="info" :class="className">
        <i class="el-icon-download"></i>{{ lintTitle }}
      </el-link>
    </el-tooltip>
  </div>
</template>

<script>
import FileSaver from 'file-saver';

export default {
  name: "ExportLink",
  props: {
    canExport: {
      type: Boolean,
      default() {
        return false
      }
    },
    lintTitle: {
      type: String,
      default() {
        return '导出表格';
      }
    },
    className:{
      type: String,
      default() {
        return 'export-excel';
      }
    }
  },
  data() {
    return {
      loadingDownload: false
    }
  },
  methods: {
    async exportSaveFile() {
      this.loadingDownload = true
      this.$emit('export')
      // try {
      //   var isFileSaverSupported = !!new Blob;
      //   // console.log('isFileSaverSupported', isFileSaverSupported)
      //   if (isFileSaverSupported) {
      //     let searchCondition = this.handleSearchCondition()
      //     let response = await this.$api.downloadTrendDatum(searchCondition)
      //     let data = response.data
      //     let contentType = response.headers['content-type']
      //     let name = '涨粉数据'
      //     let blob = new Blob([data], {
      //       type: contentType
      //     });
      //     FileSaver.saveAs(blob, decodeURI(name + ".xlsx"), { autoBom: true });
      //   } else {
      //     this.$message.warning('浏览器不支持导出文件')
      //   }
      // } catch (e) {
      //   this.$message.warning('导出异常，请联系管理员')
      //   // alert(JSON.stringify(e))
      // }
    },
    //导出excel文件
    exportSaveXlsx(response, title) {
      let isFileSaverSupported = !!new Blob;
      if (isFileSaverSupported) {
        let data = response.data
        let contentType = response.headers['content-type']
        let blob = new Blob([data], {
          type: contentType
        });

        FileSaver.saveAs(blob, decodeURI(encodeURI(title) + ".xlsx"), { autoBom: true });
        setTimeout(() => {
          this.loadingDownload = false
        }, 500)
      } else {
        this.$message.warning('浏览器不支持导出文件')
      }
    }
  }
}
</script>

<style scoped>
.export-excel {
  /*margin-top: 20px;*/
  /*margin-right: 20px;*/
  /*margin-bottom: 1px;*/
  font-size: 16px;
  font-weight: 600;
}

</style>
